<template>
	<section class="sect_book" cover>
		<v-container>
			<v-row>
				<div class="book_form ml-auto mr-auto">
					<h2 class="text-sm-h4 text-h5 text-center text-white">Забронируйте квартиру сейчас!</h2>
					<a href="https://api.whatsapp.com/send/?phone=79095066777&text&type=phone_number&app_absent=0"
						class="animated-button1 text-center ml-auto mr-auto d-block">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						Написать на WhatsApp
					</a>
				</div>
			</v-row>
		</v-container>
	</section>
</template>

<style lang="sass" scoped>
.sect_book
	background-image: url("../assets/photo-1.jpg")
	background-repeat: no-repeat
	background-size: cover
	.book_form
		margin-top: 50%
		margin-bottom: 50%
		h2
			text-shadow: black 1px 0 10px
</style>
