<template>
	<section style="background-color: #F2E7C9;">
		<v-container class="pt-16 pb-16 text-center font-weight-thin">
			<v-row>
				<v-col md="10" offset-md="1">
					<span class="text-h5">MARS Hotel — квартирное бюро, на рынке уже более 8 лет. Предлагаем для Вас чистые,
						уютные, хорошие квартиры более 50 объектов, с современным ремонтом во всех районах города. Работаем по
						безналичному расчету, сотрудничаем с предприятиятиями. Оплата — по QR-коду, наличными, либо
						переводом.</span>
				</v-col>
			</v-row>
		</v-container>
	</section>

</template>